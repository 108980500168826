<script setup>
import Card from "@/components/Tools/Card/Card.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { computed, onMounted, ref } from "vue";
import { v4 as uuid } from "uuid";
import Config from "@/components/Settings/Config/config";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { Error } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";
import Header from "@/components/Tools/Header/Header.vue";
import { useConfigValues } from "@/components/ExternalApps/TransferApp/composables/useConfigValues";
import { useLocale } from "@/components/ExternalApps/TransferApp/composables/useLocale";

const { configValues, loadConfigValues } = useConfigValues();
const { setLanguage } = useLocale();
const headerItems = computed(() => [
  {
    type: "button",
    title: i18n.t("reportExports.save"),
    emit: "save"
  }
]);
const formRef = ref();
const formValues = ref();
const form = [
  {
    name: "ftpConfig",
    type: "group",
    compactLayout: false,
    showLabels: false,
    deleteButtonType: "button",
    fieldColClass: "col-12",
    fieldColClassXl: "col-xl-12",
    showXentralIcon: true,
    addGroupLabel: {
      de: "FTP-Konfiguration hinzufügen",
      en: "Add FTP configuration"
    },
    removeGroupLabel: {
      de: "FTP-Konfiguration entfernen",
      en: "Remove FTP configuration"
    },
    fields: [
      {
        name: "technicalName",
        type: "hidden"
      },
      {
        name: "description",
        type: "text",
        label: {
          de: "Beschreibung",
          en: "Description"
        },
        validations: {
          required: true
        }
      },
      {
        name: "type",
        type: "select",
        label: {
          de: "Typ",
          en: "Type"
        },
        options: [
          {
            value: "ftp",
            label: "FTP"
          },
          {
            value: "ftps",
            label: "FTPS"
          },
          {
            value: "sftp",
            label: "SFTP"
          }
        ]
      },
      {
        name: "passive",
        type: "checkbox",
        label: {
          de: "Passivmodus",
          en: "Passive mode"
        },
        validations: {
          required: true
        }
      },
      {
        name: "host",
        type: "text",
        placeholder: "host",
        label: {
          de: "FTP Host",
          en: "FTP Host"
        },
        validations: {
          required: true
        }
      },
      {
        name: "port",
        type: "text",
        placeholder: "port",
        label: {
          de: "Port",
          en: "Port"
        },
        validations: {
          required: true
        }
      },
      {
        name: "user",
        type: "text",
        placeholder: "user",
        label: {
          de: "FTP-Nutzer",
          en: "FTP user"
        },
        validations: {
          required: true
        }
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        showXentralIcon: true,
        label: {
          de: "FTP-Passwort",
          en: "FTP password"
        },
        validations: {
          required: true
        }
      },
      {
        name: "directory",
        type: "text",
        placeholder: "directory",
        label: {
          de: "FTP-Verzeichnis",
          en: "FTP directory"
        },
        validations: {
          required: true
        }
      }
    ]
  }
];

const formConfig = {};
const deletedConfigValues = computed(() => {
  const technicalNames =
    formValues.value?.ftpConfig?.map(formValue => formValue.technicalName) ??
    [];

  return (
    configValues.value?.filter(
      config => !technicalNames.includes(config.value.technicalName)
    ) ?? []
  );
});

async function save() {
  if (!formRef.value.validate()) return;

  addEventToLoadingQueue({ key: "save-config-values" });

  let configRequests = [];

  for (/** @type {Object} */ const item of formValues.value?.ftpConfig || {}) {
    const configValue = getConfigValueByName(item.technicalName);

    if (item.technicalName) {
      configRequests.push(
        Config.update(configValue.id, {
          name: configValue.name,
          label: configValue.label,
          type: configValue.type,
          value: item
        })
      );

      continue;
    }

    const name = "ftpConfig-" + uuid();
    item.technicalName = name;
    configRequests.push(
      Config.store(
        {
          name: name,
          label: name,
          type: "json",
          value: item
        },
        true
      )
    );
  }

  for (/** @type {Object} */ const configValueToDelete of deletedConfigValues.value) {
    configRequests.push(Config.delete(configValueToDelete.id));
  }

  try {
    await Promise.all(configRequests);
  } catch (e) {
    Error(e);
  }

  await loadConfigValues();

  removeEventFromLoadingQueue({
    key: "save-config-values",
    type: "success",
    prefix: "reportExports",
    name: "saveFinished"
  });
}

/**
 * @param {string} technicalName
 * @returns {object}
 */
function getConfigValueByName(technicalName) {
  return (
    configValues.value?.find(
      item => item.value.technicalName === technicalName
    ) ?? {}
  );
}

onMounted(async () => {
  addEventToLoadingQueue({ key: "load-config-values" });

  await setLanguage();

  try {
    await loadConfigValues();
    formValues.value.ftpConfig = configValues.value.map(ftp => ftp.value);
  } catch (e) {
    Error(e);
  }

  removeEventFromLoadingQueue({ key: "load-config-values" });
});
</script>

<template>
  <div class="ftp-card__wrapper">
    <Header
      :items="headerItems"
      :show-back-button="false"
      @save="save"
    ></Header>
    <Card class="ftp-card">
      <FormHelper
        ref="formRef"
        v-model="formValues"
        :form="form"
        :config="formConfig"
      />
    </Card>
  </div>
</template>

<style scoped lang="scss">
.ftp-container {
  display: flex;
  place-items: center;
}

.ftp-card {
  overflow: auto;
  width: 100%;
  padding: 24px;
  max-height: 90vh;

  :deep(.form-group) {
    .input-group {
      max-width: 45rem;
    }
  }

  .ftp-button {
    position: sticky;
    top: 0;
    width: 100px;
    right: 0;
    align-self: flex-end;
    z-index: 100;
  }

  &__wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
  }
}
</style>
